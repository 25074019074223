import React from 'react'

export const TermsOfService = () => {
  return (
    <div className='termsPage'>
        <div className="container">
            <h2>Terms of Use</h2>
            <p>VERSION 1.1 (06.12.2024)</p>
            <p>These Terms of Use (the “Terms”) apply to</p>
            <ul>
                <li>the website https://solstech.io/</li>
                <li>any of the subdomains of it (the “Website”) and/or services oered by using the Website.</li>
            </ul>
            <p>The Website is an ocial website of Sols Tech OÜ, 16883148, Harju maakond, Tallinn, Kesklinna linnaosa, Tornimäe tn 5, 10145 (hereinafter referred as the “Company”).</p>
            <p>By accessing or using the Website and any services made available through the Website and aliated websites, if any, (collectively, the "Services"), you ("User" and collectively with others using the Website "Users") agree without any modications to be bound by these Terms.</p>
            <p>The Services are provided by the Company, and the terms "we," "us," and "our" refer to this legal entity. The Company may change the scope of the Services or stop providing Services in entirety at any time.</p>
            <p>Certain Services may be performed by dierent parties, including subsidiaries, aliates or subcontractors of the Company, and in such event Company will notify you of the name of such service provider</p>
            <p>These Terms aect your legal rights and obligations, including your right to le a lawsuit in court. If you do not agree to be bound by these Terms, do not access or use the Services. The information made available on our Website may be altered or removed at any time without prior notice. Company reserves the right to change or modify the terms and conditions contained in these Terms, including but not limited to any policy or guideline of the Website, at any time and from time to time at its sole discretion without your prior consent. We will provide notice that a change has been made to these Terms by posting the revised Terms to the Website and changing the "Last Revised" date at the top of the Terms and by emailing Users at their provided email addresses, or by any other means as determined by Company.</p>
            <p>Subject to applicable law, the method of notication will be left to Company's sole discretion. Using a particular form of notice in some instances does not obligate us to use the same form in other instances, unless Company is duty bound to do so under any applicable law. Any changes or modications will be eective immediately upon posting the revisions on the Website or at the instant that Company transmits the information to the Users (e.g. via email). These changes will apply at that instant to all then current and subsequent users of the Website. Your continued use of this Website acts as acceptance of such changes or modications. If you do not agree to the Terms in eect when you access or use the Website, you must stop using it.</p>
            
            <h2>Preamble</h2>
            <p>Company provides the Services related to Users can access a wide range of software development services oered by Sols Tech. These include:</p>
            <p>Developing e-commerce platforms for secure online transactions and optimized shopping experiences.</p>
            <p>Collaborating with our team to implement intuitive UI/UX designs that improve user interaction.</p>
            <p>You agree to use the Services only for the purposes that are permitted by the Terms and any applicable laws, regulations or generally accepted practices or guidelines in the relevant jurisdictions.</p>

            <h2>Intellectual property and copyright of Feedback materials</h2>
            <p>All information, data and content of a graphic nature, any other subject matter recognized as intellectual property under any applicable laws available on our Website is the property of Company and its related entities and aliates.</p>
            <p>You may not copy, duplicate, print or circulate the information of this Website without our prior written consent. </p>
            <p>You acknowledge and agree that any materials, including but not limited to questions, comments, feedback, suggestions, ideas, plans, notes, drawings, original or creative materials or other information or commentary you provide on our Website or one of our social media accounts, regarding Company or the Services (collectively, "Feedback") that are provided by you, whether by email, posting to the Website or otherwise, are non-condential and will become the sole property of Company.</p>
            <p>Company will own exclusive rights, including all intellectual property rights, and will be entitled to the unrestricted use and dissemination of such Feedback for any purpose, commercial or otherwise, without acknowledgement or compensation to you. </p>
            <p>Company grants you a non-exclusive, perpetual and non-transferable license to use the Website, solely for the purposes indicated herein and you shall have no right to resell or otherwise distribute any content of the Website.</p>
            
            <h2>Condentiality of the transmission of information over the Internet</h2>
            <p> The transmission of data or information over the Internet or other publicly accessible networks may not always be secure and is subject to possible loss, interception, or alteration while in transit. Accordingly, Company does not assume any liability for any loss or damage you may experience or costs you may incur as a result of any transmissions over the Internet or other publicly accessible networks, including but not limited to transmissions involving the Website or email with Company containing your personal information. While Company will make all commercially reasonable eorts to safeguard the privacy of the information you provide to Company and will treat such information in accordance with Company's Privacy Policy, in no event will the information you provide to Company be deemed to be, condential, create any duciary obligations for Company, or result in any liability for Company in the event that such information is accessed by third parties without Company's consent.</p>
            <p>When someone wants to know our clients before partnership or onboarding Sols Tech.</p>

            <h2>Limited license</h2>
            <p>Company grants you a limited, nonexclusive, non-transferable license, subject to these Terms, to access and use the Website, and the content, materials, information, and functionality available in connection therewith (collectively, the "Content") solely for information or other approved purposes as expressly permitted by Company from time to time. </p>
            <p>Any other use of the Website or Content is expressly prohibited.</p>
            <p>You will not copy, transmit, distribute, sell, resell, license, decompile, reverse engineer, disassemble, modify, publish, participate in the transfer or sale of, create derivative works from, perform, display, incorporate into another website, or in any other way exploit any of the Content or any derivative works thereof, in whole or in part for commercial or non-commercial purposes. Without limiting the foregoing, you will not frame or display the Website or Content (or any portion thereof) as part of any other website or any other work of authorship without the prior written permission of Company. </p>
            <p>If you violate any portion of these Terms, your permission to access and use the Website may be suspended and/or terminated immediately pursuant to these Terms. In addition, we reserve the right to avail ourselves of all remedies available at law for any such violation. "Sols Tech OÜ" and all logos related to the Services or displayed on the Website are either trademarks or registered marks of Company. </p>
            <p> You may not copy, imitate or use them without Company's prior written consent. You will not, nor will you authorize or encourage any third party to</p>
            <p>(1) use the Services to upload, transmit or otherwise distribute any content that is unlawful, defamatory, harassing, abusive, fraudulent, obscene, contains viruses, or is otherwise objectionable as reasonably determined by Company; </p>
            <p>(2) use any robot, spider, other automated devices, or manual process to monitor or copy any content from the Services.</p>
            
            <h2>Applicable Law and Jurisdiction</h2>
            <p>You understand and accept that the relationship between you and Company shall be governed in all respects by the laws of Estonia without regard to its conict of law provisions.</p>
            <p>If any provision of these Terms is held to be invalid, void due to contradicting law or for any reason unenforceable, such provisions shall be struck out and shall not aect the validity and enforceability of the remaining provisions. </p>
            <p>Any dispute, controversy or claim arising out of or in connection with this Agreement shall be subject to jurisdiction where the Representative of Sols Tech OÜ is domiciled. Both Parties consent to the jurisdiction of the courts located within the Jurisdiction. </p>
            <p>Any dispute arising out of or related to these Terms is personal to you and you hereby agree that such dispute will not be brought as a class arbitration, class action or any other type of representative proceeding. There will be no class arbitration or arbitration in which individual attempts to resolve a dispute as a representative of another individual or group of individuals. Further, a dispute cannot be brought as a class or other type of representative action, whether within or outside of arbitration, or on behalf of any other individual or group of individuals. </p>
            
            <h2>Limitation of Liability</h2>
            <p>Company does not assume any liability for damages or losses related to the Website, including the use of any information made available on the Website. Company also shall not be held liable for the content of hyperlinks to Websites of third parties. </p>
            
            <h2>Communication</h2>
            <p>You agree and acknowledge that all agreements, notices, disclosures and other communications that Company provides pursuant to these Terms, may be provided in electronic form. </p>
            <p>If you have any questions regarding these Terms, you are welcome to contact us by email at ahmadibtisam647@gmail.com.</p>
            
        </div>
    </div>
  )
}
