import './App.css';
import { Home } from './component/home';
import {
  createBrowserRouter,
  RouterProvider,
  useLocation,
} from "react-router-dom";
import { TermsOfService } from './component/termsOfService';

function App() {

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/terms-of-service",
      element: <TermsOfService />,
    },
  ]);
  return (
    <div className="App">
      {/* <Home /> */}
      <RouterProvider router={router} />

    </div>
  );
}

export default App;
