import React from 'react'
import logo from '../images/logo.svg';
import sec1Img from '../images/sec1Img.jpg';
import getStartedArrow from '../images/getStartedArrow.svg';
import sec1Line from '../images/sec1Line.png';
import solestech1 from '../images/solestech1.png';
import solestech2 from '../images/solestech2.png';
import whiteArrow from '../images/whiteArrow.svg';
import {ReactComponent as ArrowRight} from '../images/arrowRight.svg'
import sec4Img from '../images/sec4Img.png';
import serviceMobile from '../images/serviceMobile.png';
import hamberger from '../images/hamberger.svg';
import star from '../images/star.svg';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { Footer } from './footer';

export const Home = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        arrows: false,
        autoplaySpeed: 5000,
        cssEase: 'linear',
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },

        ]
    };

  return (
    <div className='HomePage'>
        <nav className="navbar navbar-expand-lg py-4 ">
            <div className="container">
            <a className="navbar-brand" href="#">
                <img src={logo} alt="logo" />
            </a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <img src={hamberger} alt="" />

            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav m-auto mb-2 mb-lg-0">
                    <li className="nav-item">
                        <a className="nav-link active" aria-current="page" href="#">Home</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#about">About</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#portfolio">Experties</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#services">Services</a>
                    </li>
               
                    {/* <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Dropdown
                        </a>
                        <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li><a className="dropdown-item" href="#">Action</a></li>
                        <li><a className="dropdown-item" href="#">Another action</a></li>
                        <li><hr className="dropdown-divider" /></li>
                        <li><a className="dropdown-item" href="#">Something else here</a></li>
                        </ul>
                    </li> */}
                </ul>
                <div>
                <a className="btn themeBtn" type="submit" href='mailto:info@solstech.io'>Contact Us</a>
                </div>
            </div>
            </div>
        </nav>
        <section className='sec1'>
            <div className="container position-relative">
                <div className="row al-fe">
                    <div className="col-md-8">
                        <div className="col1">
                            {/* <div className="weCanHelp">We Can Help You</div> */}
                            <h1>Your <span>One-Stop-Shop</span> for All Your Software Development Needs</h1>
                            <div className="line">
                                <img src={sec1Line} alt="" />
                            </div>
                            {/* <div className="para">We aren’t your average, run-of-the-mill website designer—our services pack a serious punch. We are Digital agency for websites that achieve goals.</div> */}
                            <div className="getStartMain">
                                <a className="themeBtn" href='mailto:info@solstech.io'><span>Get Started</span> <img src={getStartedArrow} alt="" /> </a>
                                {/* <button className="learnBtn">LEARN MORE</button> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="imgDiv">
                            <img src={sec1Img} alt="banner" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="sec2" >
            <div className="container">
                <div className="sec2M">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="val">2</div>
                            <div className="title">Years Experience</div>
                        </div>
                        <div className="col-md-3">
                            <div className="val">6</div>
                            <div className="title">Total Clients</div>
                        </div>
                        <div className="col-md-3">
                            <div className="val">12</div>
                            <div className="title">Average Review</div>
                        </div>
                        <div className="col-md-3">
                            <div className="val">17</div>
                            <div className="title">Professional Team</div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className='sec3' id='about'>
            <div className="container">
                <div className="row ai-center">
                    <div className="col-lg-6">
                        <div className="row">
                            <div className="col-6">
                                <div className="imgDiv up"><img className='img-fluid' src={solestech1} alt="" /></div>
                            </div>
                            <div className="col-6">
                                <div className="imgDiv"><img className='img-fluid' src={solestech2} alt="" /></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="content">
                            <div className="title"><span></span>About Solstech</div>
                            <h1 className="homeHeading">Agency that thinks like you do</h1>
                            <div className="homePara mb-4">With over a decade of experience in the industry, the SOLS TECH team comprises a group of highly skilled developers, designers, and project managers who are passionate about Blockchain development. Our track record speaks for itself, having developed award-winning applications for both Android and IOS platforms.</div>
                            <a className='themeBtn themeBtn2' href='mailto:info@solstech.io'>Get Started <img className='ms-2' src={whiteArrow} alt="" /></a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className='sec4'>
            <div className="container">
                <div className="row ai-center">
                    <div className="col-lg-6">
                        <div className="content">
                            <h1 className="homeHeading">Our approach</h1>
                            <div className="homePara mb-4">At SOLS TECH, every project is approached with a level of professionalism, quality, and creativity that is second to none. We believe in providing comprehensive solutions that meet the needs of our clients, and our team works closely with you to understand your goals, objectives, and vision for your project. We follow industry best practices, utilizing agile development methodologies, and the latest technologies to ensure that your project is delivered on time, within budget, and to your satisfaction.</div>
                            <a className='themeBtn themeBtn2' href='mailto:info@solstech.io'>Get Started <img className='ms-2' src={whiteArrow} alt="" /></a>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="imgDiv">
                            <img className='img-fluid' src={sec4Img} alt="" />
                        </div>
                    </div>
                </div>

            </div>
        </section>
        <section className='serviceSec' id='services'>
            <div className="container">
                <h1 className="homeHeading">Our Services</h1>
                <div className="row">
                    <div className="col-xxl-5 col-xl-4">
                        <div className="homePara">SOLS TECH offers a range of different services that are tailored to meet the unique needs of each client</div>
                        <div className="sCard">
                            <div className='d-flex'>
                                <div className="no">01</div>
                                <div className="title">UI/UX Design</div>
                            </div>
                            <ArrowRight />
                        </div>
                        <div className="sCard">
                            <div className='d-flex'>
                                <div className="no">02</div>
                                <div className="title">App Development</div>
                            </div>
                            <ArrowRight />
                        </div>
                        <div className="sCard">
                            <div className='d-flex'>
                                <div className="no">03</div>
                                <div className="title">Blockchain Development</div>
                            </div>
                            <ArrowRight />
                        </div>
                        <div className="sCard mb-0">
                            <div className='d-flex'>
                                <div className="no">04</div>
                                <div className="title">E-Commerce Development</div>
                            </div>
                            <ArrowRight />
                        </div>
                    </div>
                    <div className="col-xxl-7 col-xl-8">
                        <div className="row">
                            <div className="col-sm-7">
                                <div className="imgDiv">
                                    <img className='img-fluid' src={serviceMobile} alt="" />
                                </div>
                            </div>
                            <div className="col-sm-5">
                                <div className="howCard">
                                    <p>we offer a comprehensive suite of services</p>
                                    <div className="seeHow">
                                        <div className="title">See how we work</div>
                                        <div className="arrow"><img src={whiteArrow} alt="arrow" /></div>
                                    </div>
                                </div>
                                <div className="meetCard">
                                    <p>Looking for design experts who can bring your vision to life?</p>
                                    <div className="seeHow">
                                        <div className="title">Meet our expert</div>
                                        <div className="arrow"><img src={whiteArrow} alt="arrow" /></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className='sec4' id='portfolio'>
            <div className="container">
                <div className="content text-center">
                    <h1 className="homeHeading">Our Expertise</h1>
                    <div className="homePara mb-4">Our expertise lies in developing mobile applications that are not only functional but also visually appealing and deliver a seamless experience to users. Our developers are proficient in multiple programming languages, including Java, Swift, and Kotlin. We also specialize in integrating third-party APIs, cloud services, and advanced analytics, to enhance the performance and functionality of your application.</div>
                    <a className='themeBtn themeBtn2' href='mailto:info@solstech.io'>Get Started <img className='ms-2' src={whiteArrow} alt="" /></a>
                </div>
            </div>
        </section>
        <section className='sec5'>
            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <div className="card1">
                            <div className="title"><span></span>UI/UX</div>
                            <div className="head">Creative Service</div>
                            <div className="para">SOLS TECH offers a range of UI/UX design services that are tailored to meet the unique needs of each client</div>
                            <a className='readMore' href="#">Read More <img src={whiteArrow} alt="" /></a>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card1 card2">
                            <div className="title"><span></span>Development</div>
                            <div className="head">Product Development</div>
                            <div className="para">Developing mobile applications that are not only functional but also visually appealing and deliver a seamless experience to users.</div>
                            <a className='readMore' href="#">Read More <img src={whiteArrow} alt="" /></a>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card1 card3">
                            <div className="title"><span></span>App Testing & Migration</div>
                            <div className="head">App Functionality</div>
                            <div className="para">Our developers are proficient in multiple programming languages, including Java, Swift, and Kotlin.</div>
                            <a className='readMore' href="#">Read More <img src={whiteArrow} alt="" /></a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className='sec7'>
            <div className="container">
                <div className="row ">
                    <div className="col-md-6">
                       <div className="homeHeading">Why Choose <br /> <span className='gradColor'>SOLS TECH?</span></div>
                    </div>
                    <div className="col-lg-6">
                        <div className="content">
                            <div className="homePara mb-4">Committed to delivering quality solutions that meet your needs Experienced team of developers, designers, and project managers Utilization of agile development methodologies and advanced technologies Full-service blockchain development company. We work with you every step of the way to ensure your satisfaction</div>
                            <a className='themeBtn themeBtn2' href='mailto:info@solstech.io'>Get Started <img className='ms-2' src={whiteArrow} alt="" /></a>
                        </div>
                    </div>
                </div>

            </div>
        </section>
        <section className='sec4'>
            <div className="container">
                <div className="content text-center">
                    <div className="homeHeading gradColor">Conclusion</div>
                    <div className="homePara mb-4">Partner with SOLS TECH today for all your mobile application needs. We are committed to delivering solutions that exceed your expectations, enhancing your brand, and transforming your ideas into reality. Contact us today for a free consultation and take the first step towards achieving your goals.</div>
                    <a className='themeBtn themeBtn2' href='mailto:info@solstech.io'>Get Started <img className='ms-2' src={whiteArrow} alt="" /></a>
                </div>
            </div>
        </section>

{/*
        <section className='testimonialSec'>
            <div className="container">
                <div className="title"><span></span>Testimonials</div>
                <div className="row">
                    <div className="col-md-6">
                        <h1 className="homeHeading">Review from our client</h1>
                    </div>
                    <div className="col-md-6">
                        <h1 className="homePara">Solstech Powering businesses with innovative UI/UX and development solutions. We specialize in crafting tailored, scalable UI/UX designs to boost productivity and drive growth. Partner with us for excellence in App development.</h1>
                    </div>
                </div>
                <div className="silderMain">
                    <Slider {...settings}>
                
                    <div className="px-3">
                        <div className="testCard">
                            <div className="row1">
                                <div className="profM">
                                    <div className="imgDiv"><img src={userProfile1} alt="" /></div>
                                    <div>
                                        <div className="name">Ibtisam Ahmed</div>
                                        <div className="desig">CEO Lexica</div>
                                    </div>
                                </div>
                                <div className="rate"><img src={star} alt="" /> 4.8</div>
                            </div>
                            <p className="para">"Working with Solstech was a game-changer for our business. Their cutting-edge technology and dedicated team streamlined our processes, making a significant impact on our productivity. Exceptional service!"</p>
                        </div>
                    </div>
                    <div className="px-3">
                        <div className="testCard">
                            <div className="row1">
                                <div className="profM">
                                    <div className="imgDiv"><img src={userProfile1} alt="" /></div>
                                    <div>
                                        <div className="name">Amanda Starr</div>
                                        <div className="desig">Co Founder</div>
                                    </div>
                                </div>
                                <div className="rate"><img src={star} alt="" /> 4.8</div>
                            </div>
                            <p className="para">"Working with Solstech was a game-changer for our business. Their cutting-edge technology and dedicated team streamlined our processes, making a significant impact on our productivity. Exceptional service!"</p>
                        </div>
                    </div>
                    <div className="px-3">
                        <div className="testCard">
                            <div className="row1">
                                <div className="profM">
                                    <div className="imgDiv"><img src={userProfile1} alt="" /></div>
                                    <div>
                                        <div className="name">Steve Raymond</div>
                                        <div className="desig">CEO Lexica</div>
                                    </div>
                                </div>
                                <div className="rate"><img src={star} alt="" /> 4.8</div>
                            </div>
                            <p className="para">"Working with Solstech was a game-changer for our business. Their cutting-edge technology and dedicated team streamlined our processes, making a significant impact on our productivity. Exceptional service!"</p>
                        </div>
                    </div>
                    </Slider> 
                </div>
            </div>
        </section> */}


        <section className='sec7'>
            <div className="container">
                <div className="content text-center">
                    <div className="homeHeading mx-auto">Have a <span className='gradColor'>Project</span> in mind? Let’s get to <span className='gradColor'>Work!</span></div>
                    <a className='weCanHelp py-2 px-4' href='mailto:info@solstech.io'>Contact Us <img className='ms-2' style={{width:8}} src={whiteArrow} alt="" /></a>
                </div>
            </div>
        </section>

        <Footer />
    </div>
  )
}
